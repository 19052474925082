<template>
    <div id="UserControlPreferences">
      <p>Control how you hear from us:</p>
      <div class="row">
        <div class="mb-5 col-12">
          <div class="row">
            <div class="col-8 d-flex align-items-center">
              Email
            </div>
            <div class="col">
              <label class="switch">
                <input type="checkbox" v-model="user.user_settings.email_notifications"
                       :checked="user.user_settings.email_notifications"
                       @change="updateUserDetails">
                <span class="slider round"></span>
              </label>
            </div>

          </div>
        </div>
        <div class="mb-5 col-12">
          <div class="row">
            <div class="col-8 d-flex align-items-center">
              Push Notifications
            </div>
            <div class="col">
              <label class="switch">
                <input type="checkbox" v-model="user.user_settings.push_notifications"
                       :checked="user.user_settings.push_notifications"
                       @change="updateUserDetails">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import axios from "axios";
import config from "@/config/constants";
import Popup from "@/components/Popup.vue"
import {mapState} from "vuex";

export default {
    name: "UserControlPreferences",
    components: {
        Popup
    },
    data() {
        return {
          userDetailsPopupContent: {
            header: '',
            message: ''
          },
          showUserDetailsPopup: false
        };
    },
    mounted() {
    },
    methods: {
      hideUserDetailsPopup(){
        this.showUserDetailsPopup = false
      },
      async updateUserDetails() {
        const payload = {
          user_id: this.$store.getters.user_id,
          user: this.user.user,
          user_profile: this.user.user_profile,
          user_settings: this.user.user_settings
        }
        let res = await axios.post(config.api_env + '/application/api/u/user/update-user-profile', payload, config.options)
            .then((response) => {
              this.userDetailsPopupContent.header = (response.data.success === true) ? 'Success!' : 'Error'
              this.userDetailsPopupContent.message = response.data.message
              this.showUserDetailsPopup = true
            }, (error) => {
              this.userDetailsPopupContent.header = 'Error'
              this.userDetailsPopupContent.message = error
              this.showUserDetailsPopup = true
            });
      },
    },
    computed: {
      ...mapState(['user']),
      componentText() {
        return this.getText.loggedIn.settings;
      },
    },
    watch: {},
};
</script>

<style lang="less">
#UserControlPreferences {
  min-height: 350px;
  padding-bottom: 20px;
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider:before {
    background-color: #E83B75;
  ;
  }

  input:focus + .slider:before {
    box-shadow: 0 0 1px #E83B75;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border: 1px solid #ccc;
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
</style>
